html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}
html {
  height: 100%;
}
body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-size: 100%;
  padding:0;
  margin: 0;
  font-family: $primary-font;
  font-weight:400;
}
h1, h2, h3, h4, h5, h6 {
  color:black;
  font-weight:700;
  font-family: $primary-font;
  margin:0;
  padding:0;
  position:relative;
}
h2 {
  font-size:46px;
  line-height:1.4em;
}
h3 {
  font-size:36px;
}
p, ul, ol, li {
  color:$font-color;
  font-size:17px;
  font-weight:400;
  line-height:32px;
  font-family: $primary-font;
}
p, ul, ol {
  margin:0 0 28px 0;
}

a {
  color:$primary-color;
  &:hover,
  &:focus {
    color:$primary-color;
  }
}
a, button, input {
  &:focus {
    outline:0;
  }
}

section {
  width:100%;
  float:left;
  position:relative;
  display:block;
  &.bg {
    background:url('/images/bg.jpg') repeat;
    min-height:300px;
    .container {
      position:relative;
      z-index:1;
    }
    &:after {
      content:"";
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:0;
      background: -moz-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 100%);
      background: -webkit-linear-gradient(top, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
      background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#00f7f7f7',GradientType=0 );
      z-index:0;
    }
    h2 {
      padding-bottom:30px;
      margin-bottom:40px;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
        background:$primary-color;
        width:110px;
        height:5px;
      }
    }
  }
  &.padded {
    padding:75px 0;
  }
  &.padded-small {
    padding:45px 0;
  }
  &.pink {
    background:$primary-color;

    h1, h2, h3, h4, h5, h6, p, ul, ol, li {
      color:white;
    }
    h1, h2, h3, h4, h5, h6 {
      padding-bottom:30px;
      margin-bottom:40px;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
        background:white;
        width:110px;
        height:5px;
      }
    }
  }
  &.main {
    background:#f7f7f7;
  }
}

.text-block {
  h2 {
    font-size:26px;
  }
  h3 {
    font-size:21px;
  }
  p, ul, ol {
    margin:0 0 36px 0;
  }
  ul {
    list-style:none;
    margin:0 0 30px 0;
    padding:0;
    li {
      position:relative;
      padding-left:25px;
      &:before {
        content:"\f00c";
        font-family: FontAwesome;
        position:absolute;
        left:0;
        top:-1px;
        font-size:20px;
        color:$primary-color;
      }
    }
  }
  .btn-primary {
    padding:6px 10px;
    font-size:16px;
  }
  img {
    max-width:100%;
    height:auto !important;
    display:block;
  }
  &.center {
    text-align:center;
  }
}