.text-about {
  float:left;
  width:100%;
  background:white;
  position:relative;
  padding:30px 30px 0 30px;
  margin:0 0 45px 0;
  z-index:1;
  p, ul, ol, li, blockquote {
    position:relative;
    z-index:10;
    font-style: italic;
  }
  &:after {
    content:"\f10e";
    font-family: FontAwesome;
    position:absolute;
    z-index:0;
    top:60px;
    left:60px;
    font-size:300px;
    color:#f7f7f7;
  }
}
.portret {
  background:black;
  padding:10px;
  border-radius:50%;
  position:relative;
  max-width:300px;
  z-index:1;
  img {
    position:relative;
    z-index:5;
  }
  &:after {
    content:"";
    position:absolute;
    right:-12px;
    top:200px;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 40px solid black;
    transform:rotate(30deg);
    z-index:0;
  }
}
.certified {
  float:left;
  margin:0 0;
  max-height:80px;
}
.certified + .certified {
  margin-left:30px;
}