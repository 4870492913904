@media only screen and (max-width: 1499px) {
  .navbar-fixed-top {
    .brand {
      width:470px;
    }
    .navbar-right {
      li {
        a {
          font-size:18px;
          padding:10px 10px;
        }
        &.dropdown {
          .dropdown-toggle {
            &:after {
              top:9px;
            }
          }
        }
      }
    }
    &.bg-fixed {
      .navbar-right {
        margin-top:27px;
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  h2 {
    font-size:37px;
  }

  section {
    &.padded {
      padding:60px 0;
    }
  }

  header {
    height:400px;
    min-height:400px;
  }

  .navbar-fixed-top {
    .brand {
      width:400px;
      height:57px;
      margin-top:40px;
      img {
        height:auto !important;
      }
    }
    &.bg-fixed {
      .brand {
        margin-top:25px;
      }
      .navbar-right {
        margin-top:30px;
      }
    }
  }

  #slider-container {
    #slider {
      .slider-title {
        h1 {
          font-size:37px;
        }
        h2 {
          font-size:27px;
        }
      }
    }
  }

  .card {
    .short {
      h3 {
        font-size:21px;
        margin:0 0 4px;
      }
    }
  }
}

@media only screen and (max-width: 1299px) {
  .navbar-fixed-top {
    .brand {
      width:320px;
      height:49px;
      margin-top:48px;
    }
    .navbar-right {
      li {
        a {
          padding:10px 9px;
          font-size:17px;
        }
      }
    }
    &.bg-fixed {
      .brand {
        width:320px;
        height:49px;
        margin-top:25px;
      }
    }
  }

  #slider-container {
    #slider {
      .slider-title {
        width:65%;
      }
    }
  }

  #nieuwsgierig {
    right:-90px;
  }
}