#references {
  .card {
    margin:30px 0 0 0;
    .reference-image {
      height:160px;
      width:100%;
      float:left;
      background:white;
      position:relative;
      display:block;
      img {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        display:block;
        max-width:65%;
        max-height:100px;
      }
    }
    .short {
      h3 {
        font-size:21px;
        margin:0 0 5px 0;
      }
    }
  }
  h2 {
    padding-bottom:30px;
    margin-bottom:40px;
    &:after {
      content:"";
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      margin:0 auto;
      background:$primary-color;
      width:110px;
      height:5px;
    }
  }
  .btn-primary {
    margin-top:30px;
  }
  .row-opdrachtgevers {
    h2 {
      margin-top:45px;
    }
  }
}

.referentie {
  float:left;
  width:100%;
  height:160px;
  display:block;
  background:white;
  border-bottom:5px solid $primary-color;
  margin:30px 0 0 0;
  position:relative;

  img {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    display:block;
    max-width:65%;
    max-height:100px;
  }
}
#referentie-slider {
  padding:0 0 50px 0;
  display:block;
  float:left;
  width:100%;
  .owl-nav {
    position:absolute;
    top:35%;
    left:50%;
    width:100%;
    height:30px;
    transform:translate(-50%,-50%);
    z-index:1001;
  }
  .owl-prev,
  .owl-next {
    display:block;
    position:absolute;
    top:0;
    width:20px;
    height:30px;
    opacity:0.5;
    z-index:1000;
    span {
      display:none;
    }
    &:before {
      font-family: FontAwesome;
      position:absolute;
      color:light($grey, 60%);
      font-size:60px;
      line-height:30px;
      vertical-align: top;
      top:0;
    }
    &:hover,
    &:focus {
      opacity:1;
    }
  }
  .owl-prev {
    position:absolute;
    left:0;
    &:before {
      content:"\f104";
      left:0;
    }
  }
  .owl-next {
    position:absolute;
    right:0;
    &:before {
      content:"\f105";
      left:0;
    }
  }

}
.klant-vertelt {
  display:block;
  position:relative;
  background:white;
  max-width:800px;
  margin:0 auto;
  padding:30px;
  z-index:10;
  margin-bottom:50px;
  .author {
    color:$primary-color;
    font-weight:700;
    display:block;
  }
  .stars {
    display:block;
    margin:0 0 15px 0;
    i {
      margin:0 3px 0 0;
      font-size:21px;
      color:#FFA500;
    }
  }
  &:after {
    content:"";
    width:50px;
    height:50px;
    position:absolute;
    bottom:-25px;
    left:150px;
    background:white;
    z-index:0;
    transform:rotate(45deg);
  }
}