.btn-primary,
.btn-secondary{
  border:0;
  padding:11px 18px;
  border-radius:4px;
  background:$primary-color;
  border-bottom:3px solid darken($primary-color,15%);
  font-weight:700;
  text-transform:uppercase;
  font-size:18px;
  transition:background-color ease-in-out 0.2s;
  cursor:pointer;
  &:hover,
  &:focus {
    outline:0;
    background:darken($primary-color,10%);
    border-color:darken($primary-color,30%);
  }
}
.btn-small {
  padding:8px 12px;
  font-size:16px;
}
.btn-secondary {
  color:$primary-color;
  background:white;
  border-bottom:3px solid darken(white,30%);
  &:hover,
  &:focus {
    background:darken(white,15%);
    border-color:darken(white,40%);
  }
}
.btn-arrow {
  padding:11px 48px 11px 18px;
  position:relative;
  &:after {
    content:"\f138";
    font-family: FontAwesome;
    font-size:24px;
    position:absolute;
    right:21px;
    top:6px;
    width:15px;
    height:15px;
  }
}