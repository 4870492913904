@media only screen and (max-width: 767px) {
  body {
    padding-top:60px;
  }

  h1 {
    font-size:32px;
  }

  h2 {
    font-size:32px;
  }

  section {
    &.padded {
      padding:30px 0;
    }
  }

  .top-nav {
    display:none;
  }

  .navbar-fixed-top {
    height:60px;
    background:white;
    .navbar-header {
      min-height:60px;
    }
    .brand {
      width: 240px;
      height: 34px;
      margin-top: 15px;
      margin-left: 15px;

      float: left;
      position: relative;
      transition: ease-in-out all 0.2s;
      img {
        max-width: 100%;
        height:auto !important;
        display: block;
        position: relative;
        transition: ease-in-out all 0.2s;
      }
    }

    .menu-toggle {
      background: transparent;
      border: 0;
      float: right;
      width: 40px;
      height: 35px;
      position: relative;
      margin: 18px 15px 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 2px;
        border-radius:2px;
        width: 100%;
        background: $primary-color;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2) {
        top: 10px;
      }

      span:nth-child(3) {
        top: 20px;
      }
    }
    .menu-toggle.open span:nth-child(1) {
      top: 12px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    .menu-toggle.open span:nth-child(3) {
      top: 12px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }

    #navbar {
      background:white;
      .navbar-right {
        margin:0 -15px;
        li {
          a {
            text-align:center;
            color:black;
            text-transform: uppercase;
            font-weight:600;
            padding:15px 15px;
            font-size:20px;
            border-bottom:1px solid #f5f5f5;
          }
          &.active {
            a {
              color:white;
              background:$primary-color;
            }
          }
        }
      }
    }

    &.bg-fixed {
      .navbar-header {
        .brand {
          width: 250px;
          height: 34px;
          margin-top:15px;
          margin-left:15px;
        }
      }
    }
  }

  header {
      height:260px;
      min-height:260px;
      #header-image {
        min-height:260px;
      }
  }

  .text-block {
    padding:0 15px;
    h2 {
      font-size:32px;
    }
    iframe {
      width:100% !important;
      height:auto !important;
    }
  }

  .card {
    margin:0 0 30px 0;
  }

  #slider-container {
    #slider {
      .slider-title {
        h1 {
          font-size:27px;
        }
        h2 {
          font-size:18px;
        }
        h1, h2 {
          margin:5px 0 0 0;
        }
        h1 + h2 {
          display:none;
        }
        .btn-primary {
          margin-top:5px;
        }
      }
    }
  }

  #werkoverleg {
    .btn-arrow {
      font-size:15px;
      padding:11px 48px 11px 11px;
      &:after {
        font-size:21px;
      }
    }
  }

  .portret {
    z-index:3;
    margin:0 auto;
    &:after {
      right: 18px;
      top: 195px;
      transform:rotate(60deg);
    }
  }

  .text-about {
    margin-top:-15px;
  }

  #references {
    h2 {
      margin-bottom:15px;
    }
    .card {
      .reference-image {
        height:125px;
      }
    }
    .referentie {
      margin:15px 0 0 0;
    }
  }

  .form-small,
  .form-medium{
    width:100%;
    padding:0 !important;
  }
  .form-small + .form-medium {
    margin-top:8px;
  }

  #contact-info {
    padding:45px 0;
    .info {
      margin:15px 0 0 0;
    }
  }

  footer {
    width:100%;
    overflow:hidden;
    ul.footer-left {
      margin:0 0 30px 0;
      li {
        width:100%;
        text-align:center;
        &:after {
          content:"";
        }
      }
    }
    ul.footer-right {
      list-style: none;
      position: relative;
      float: left;
      display: block;
      left: 50%;
      li {
        text-align:center;
        position: relative;
        float: left;
        display: block;
        right: 50%;
        margin:0 10px 0 0;
        a {
          font-size:0;
          width:50px;
          height:50px;
          display:block;
          background:$primary-color;
          border-radius:50%;
          position:relative;
          padding:0;
          &:before {
            position:absolute;
            color:white;
            top:50% !important;
            left:50% !important;
            transform:translate(-50%,-50%);
            font-size:24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {}