#werkoverleg {
  .btn-secondary {
    position:relative;
    margin:15px auto;
    &:before {
      content:"";
      position:absolute;
      right:-170px;
      top:-32px;
      background:url('/images/arrow.png') no-repeat;
      background-size:100%;
      width:100px;
      height:29px;
      transform:rotate(1deg);
      transition:ease all 0.2s;
    }
    &:hover,
    &:focus {
      &:before {
        right:-160px;
        top:-34px;
        transform:rotate(-3deg);
      }
    }
  }
}

#nieuwsgierig {
  width:275px;
  position:absolute;
  right:-130px;
  bottom:-10px;
  transform: rotate(-5deg);
  span {
    font-family: $secondary-font;
    color:white;
    font-size:30px;
  }
}