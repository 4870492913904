.card {
  float:left;
  width:100%;
  background:white;
  display:block;
  border:1px solid white;
  border-bottom:5px solid $primary-color;
  text-decoration:none;
  box-shadow:0 4px 8px rgba(0,0,0,0.1);
  transition:box-shadow ease-in-out 0.2s;
  .image {
    width:100%;
    display:block;
    padding:1px;
    position:relative;
    img {
      max-width:100%;
      display:block;
      position:relative;
      z-index:0;
    }
    &:after {
      content:"";
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.4);
      z-index:10;
    }
  }
  .short {
    background:white;
    height:235px;
    overflow:hidden;
    padding:22px;
    display:block;
    position:relative;

    h3 {
      font-size:25px;
      text-transform:uppercase;
      margin:0 0 7px 0;
      padding:0;
    }
    p {
      color:black;
      font-size:17px;
    }
    .oplossing {
      color:$primary-color;
      width:100%;
      display:block;
      float:left;
      position:absolute;
      bottom:25px;
      left:0;
      padding-left:25px;
      padding-right:25px;
      &:after {
        content:"\f061";
        position:absolute;
        right:25px;
        top:0;
        font-family: FontAwesome;
        color:$primary-color;
        transition:ease-in-out all 0.2s;
      }
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow:0 6px 12px rgba(0,0,0,0.3);
    .oplossing {
      &:after {
        font-size:24px;
        top:-4px;
        right:23px;
      }
    }
  }
}