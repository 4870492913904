@media only screen and (max-width: 1199px) {
  li, ol, p, ul {
    font-size:16px;
    line-height:29px;
  }

  .navbar-fixed-top {
    .brand {
      width:300px;
      height:43px;
      margin-top:49px;
      margin-left:0;
      img {
        height:auto !important;
      }
    }
    .navbar-right {
      margin-right:0;
      li {
        a {
          font-size:16px;
          padding:10px 10px;
        }
      }
    }
    &.bg-fixed {
      .brand {
        width:300px;
        height:43px;
      }
    }
  }

  .top-nav {
    right:30px;
  }

  #slider-container {
    #slider {
      min-height:350px;
      .slider-title {
        width:80%;
        max-width:720px;
      }
    }
  }

  .card {
    .short {
      h3 {
        font-size:19px;
      }
      p {
        font-size:16px;
      }
    }
  }

  header {
    height:50vh;
    min-height:450px;
  }

  #werkoverleg {
    .btn-secondary {
      &:before {
        content:"";
        background:transparent;
      }
    }
  }

  #nieuwsgierig {
    display:none;
  }

  .portret {
    max-width:250px;
    &:after {
      right:-8px;
      top:162px;
    }
  }

  .text-about{
    &:after {
      font-size:220px;
    }
  }

  #references {
    .card {
      .short {
        h3 {
          font-size:19px;
        }
      }
    }
  }
}