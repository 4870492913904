#slider-container {
  width: 100%;
  height: 100vh;
  min-height:100%;
  overflow: hidden;
  position: relative;
  float: left;
  display: block;
  z-index:0;
  #slider {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    div:not(.owl-controls) {
      height: 100%;
      position: relative;
    }
    .owl-item {
      height:100%;
      position:relative;
    }
    .owl-slide {
      z-index: 100;
      position: relative;
    }
    .slider-title {
      position:absolute;
      z-index:99;
      left:30px;
      bottom:30px;
      width:55%;
      display:block;
      .main-title {
        display:block;
      }

      h1, h2 {
        display:inline-block;
        background:black;
        color:white;
        padding:10px 18px;
        position:relative;
        margin:12px 0 0 0;
      }
      h1 {
        font-size:46px;
        font-weight:700;
        strong {
          color:$primary-color;
        }
      }
      h2 {
        font-size:30px;
        font-weight:300;
      }
      .main-title + h2 {
        float:left;
        margin-right:15px;
      }
      .btn-primary {
        margin-top:20px;
        float:left;
      }
    }
  }
  &:after {
    content:"";
    width:100%;
    height:190px;
    position:absolute;
    top:0;
    left:0;
    opacity:0.9;
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    z-index:10;
  }
}