header {
  height:420px;
  min-height:420px;
  width:100%;
  position:relative;
  overflow: hidden;
  display:block;
  float:left;
  z-index:0;
  #header-image {
    width:100%;
    height:100%;
    min-height:500px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    margin:0 auto;
    opacity:1;

    &:after {
      content:"";
      width:100%;
      height:100%;
      position:absolute;
      background:rgba(255,255,255,0.2);
      opacity:1;
      z-index:102;
    }
  }
  .container,
  .row,
  .col-md-10 {
    height:100%;
    position:relative;
  }
  .header-title {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    width:100%;
    z-index:199;
    h1 {
      margin:0 auto;
      color:white;
      width:100%;
      text-align:center;
      background:black;
      display:block;
      position:relative;
      padding:12px 20px;
      font-size:26px;
    }
  }
  &:after {
    content:"";
    width:100%;
    height:190px;
    position:absolute;
    top:0;
    left:0;
    opacity:0.9;
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    z-index:10;
  }
}