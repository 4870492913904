@media (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
  .top-nav {
    position: absolute;
    right: 60px;
    top: 0;
    z-index: 1111;
    ul {
      list-style: none;
      margin: 5px 0 0 0;
      li {
        float: left;
        margin: 0 0 0 25px;
        a {
          color: $grey;
          font-weight: 600;
          padding-left: 26px;
          font-size: 16px;
          position: relative;
          &:before {
            content: "";
            font-family: FontAwesome;
            position: absolute;
            color: $grey;
            font-weight: 400;
            left: 0;
            top: -6px;
            font-size: 17px;
          }
        }
        &:first-child {
          a {
            &:before {
              content: "\f003";
            }
          }
        }
        &:last-child {
          a {
            padding-left: 20px;
            &:before {
              top: -4px;
              content: "\f095";
            }
          }
        }
      }
    }
  }
  .navbar-fixed-top {
    background: transparent;
    border: 0;
    box-shadow: none;
    transition: background-color ease-in-out 0.2s;
    .brand {
      float: left;
      position: relative;
      width: 490px;
      height: 70px;
      margin: 30px 0 0 30px;
      transition: ease-in-out all 0.2s;
      img {
        max-width: 100%;
        display: block;
        position: relative;
        transition: ease-in-out all 0.2s;
      }
    }
    .navbar-right {
      margin-top: 48px;
      margin-right: 30px;
      transition: ease-in-out all 0.2s;
      li {
        margin: 0 2px;
        a {
          color: black;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 19px;
          background: transparent;
          transition: ease-in-out all 0.15s;
          &:hover,
          &:focus {
            background: $primary-color;
            color: white;
            border-radius: 4px;
          }
        }
        &.active {
          a {
            color: white;
            background: $primary-color;
            border-radius: 4px;
            &:hover,
            &:focus {
              color: white;
              background: $primary-color;
            }
          }
        }
        &.dropdown {
          .dropdown-toggle {
            position:relative;
            padding-right:30px;
            &:after {
              content:"\f0d7";
              font-family: FontAwesome;
              position:absolute;
              right:15px;
              top:15px;
              font-size:15px;
            }
            &:hover,
            &:focus {
              color: white;
              background: $primary-color;
              border-radius: 4px;
            }
          }
          .dropdown-menu {
            left:0;
            right:auto;
            border-radius:0;
            padding:2px 0;
            li {
              a {
                &:hover,
                &:focus {
                  border-radius:0;
                }
              }
              &.active {
                a {
                  border-radius:0;
                }
              }
            }
          }
          &:hover {
            .dropdown-menu {
              display:block;
            }
          }
        }
      }
    }
    &.bg-fixed {
      background: white;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      .brand {
        width: 400px;
        height: 57px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .navbar-right {
        margin-top: 24px;
      }
    }
  }
}