#contact-info {
  background: white;
  padding:75px 0;
  width:100%;
  float:left;
  position:relative;
  .contact-block {
    margin:0 auto;
    .container-fluid {
      padding:0;
    }
  }

  .info,
  .info p,
  .info a {
    color:$font-color;
    font-family: $primary-font;
    font-weight:400;
    line-height:32px;
    margin:0;
    padding:0;
    text-align:center;
    strong {
      font-size:21px;
      text-align:center;
    }
  }
}
.contact-form {
  h2 {
    font-size:26px;
  }
}
#contact-us {
  .contact-form {
    margin-top:30px;
  }
  h2 {
    padding-bottom:30px;
    margin-bottom:40px;
    text-align:center;
    &:after {
      content:"";
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      margin:0 auto;
      background:$primary-color;
      width:110px;
      height:5px;
    }
  }
  .img-responsive {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    max-width: 395px;
    float: right;
  }
}

.text-block-contact {
  max-width:420px;
  h3 {
    margin-bottom:4px;
    font-size:26px;
    line-height:34px;
  }
}

.contactgegevens {
  float:left;
  width: 100%;
  display:block;
  color:$primary-color;
  font-size:21px;
  line-height:34px;
  a {
    font-weight:700;
  }
}
