.col-form {
  padding-right:40px;
  padding-left:15px;
}
.col-form + .col-form {
  padding-left:40px;
  padding-right:15px;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: $font-color;
  height: 56px;
  padding:8px 15px 8px 15px;
  border:1px solid #eee;
  border-radius:0;
  box-shadow: none;
  background:white;
  font-family: $primary-font;
  &:focus,
  &:active {
    box-shadow: none;
    border-color: $primary-color;
  }
}
.form-half {
  float:left;
  width:50%;
  padding-right:12px;
}
.form-half + .form-half {
  float:left;
  width:50%;
  padding-left:4px;
  padding-right:0;
}
.form-small {
  float:left;
  width:25%;
  padding-right:4px;
}
.form-medium {
  float:left;
  width:75%;
  padding-right:12px;
}
.form-small + .form-medium {
  padding-left:4px;
  padding-right:0;
}
.form-medium + .form-small {
  padding-right:0;
  padding-left:4px;
}

.form-large {
  float:left;
  width:100%;
}

.form-group {
  margin: 0 0 8px 0;
  float: left;
  width: 100%;
}

textarea.form-control {
  resize: none;
  height: 250px;
  padding:8px 15px 8px 15px;
  border-radius:0;
}

.alert {
  border-radius: 0;
  font-size: 17px;
  font-family: $primary-font;
  font-weight:400;
  margin-bottom:40px;
  ul {
    margin: 0 0 0 19px;
    padding: 0;
    li {
      padding: 2px 0;
    }
  }
}

.has-error {
  position: relative;
  .form-control {
    border-color: $primary-color;
    box-shadow:inset 0 0px 0px rgba(0,0,0,.0);
    &:hover,
    &:focus {
      box-shadow:inset 0 0px 0px rgba(0,0,0,.0);
    }
  }
  &:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 14px;
    color: $primary-color;
    font-size: 20px;
  }
}

.alert-danger {
  background: lighten($primary-color,51%);
  color: $primary-color;
  border-color: $primary-color;
  border: 0;
  ul, li, p {
    margin:0 !important;
    padding:0 0 0 15px !important;
    color: $primary-color !important;
    list-style:none;
  }
  ul {
    position:relative;
  }
  li {
    position:relative;
    &:before {
      content:"\f12a";
      position:absolute;
      font-family: FontAwesome;
      left:0;
      top:1px;
      color:$primary-color;
    }
  }
}

.form-content {
  h2 {
    margin:0 0 30px 0;
    padding:0;
    text-align:center;
  }
}


span.required {
  font-size:15px;
  margin-top:15px;
  color:#abacad;
  font-weight:300;
  font-family: $primary-font;
  display:block;
}

