footer {
  background:white;
  padding:25px 10px;
  width:100%;
  float:left;
  display:block;
  border-top:1px solid #eee;
  ul.footer-left {
    margin:0;
    padding:0;
    list-style:none;
    float:left;
    position:relative;
    li {
      float:left;
      margin: 0 15px 0 0;
      padding:0 20px 0 0;
      position:relative;
      &:after {
        content:"\f111";
        font-size:6px;
        font-family: FontAwesome;
        position:absolute;
        right:0;
        top:1px;
        color:$primary-color;
      }
      &:last-child {
        &:after {
          content:"";
        }
      }
    }
  }
  .footer-right {
    float:right;
    position:relative;
    list-style:none;
    padding:0;
    margin:0;
    li {
      float:left;
      margin:0 30px 0 0;
      position:relative;
      a {
        position:relative;
        padding-left:28px;
        &:before {
          content:"";
          font-family: FontAwesome;
          position:absolute;
          color:black;
          left:0;
          top:-5px;
          font-size:20px;
        }
      }
      &:first-child {
        a {
          &:before {
            content:"\f003";
          }
        }
      }
      &:last-child {
        a {
          padding-left:26px;
          &:before {
            top:-3px;
            content:"\f095";
          }
        }
      }
    }
  }
}