@media only screen and (max-width: 1099px) {
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        width:250px;
      }
    }
    .navbar-right {
      li {
        a {
          padding:10px 7px;
        }
      }
    }
    &.bg-fixed {
      .navbar-header {
        .brand {
          margin-top:28px;
        }
      }
      .navbar-right {
        margin-top:25px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .top-nav {
    position:fixed;
  }
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        margin-top:10px;
      }
    }
    #navbar {
      .navbar-right {
        margin-top:10px;
        float:left !important;
        li {
          a {
            padding:10px 8px;
            font-size:18px;
          }
          &.dropdown .dropdown-toggle {
            padding-right:30px;
          }
        }
      }
    }
    &.bg-fixed {
      padding-bottom:15px;
      .navbar-header {
        .brand {
          margin-top:10px;
          margin-bottom:5px;
        }
      }
    }
  }
}